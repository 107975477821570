<template>
<div id="noticeManage">
   <!--头部搜索-->
   <div class="bg-white searchDom">
     <div class="flex align-center justify-end margin-bottom-24 header_search">
       <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
         <el-input  @input="searKeyword" v-model="sendTab.keyword" placeholder="请输入用户ID查询" suffix-icon="search"></el-input>
         <i style="cursor: pointer;"  class="el-icon-search" @click="searKeyword"></i>
       </div>
       <el-button @click="addDialogVisible=true" style="height: 32px;width: 68px;padding: 0;color: #FFFFFF;background-color: #4E9F5B;">添加公告</el-button>
       <el-button @click="exportData" style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;">导出</el-button>
     </div>
   </div>
  <!-- 搜索列表 -->
  <div class="searchList">
     <div class="card">                        
        <div class="card-block table-border-style">
            <div class="table-responsive">
                <Empty v-show="dataList.length=='0'"/>
                <el-table v-show="dataList.length!='0'"
                :header-cell-style="{color:'#333333',fontsize:'14px'}" :data="dataList" stripe
                style="width: 100%">
                  <el-table-column prop="message_id" align="center" label="ID">
                  </el-table-column>
                  <el-table-column prop="message_content" align="center" label="内容">
                    <template slot-scope="scope">
                      <span style="width:120px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;display: inline-block;">{{scope.row.message_content}}</span>
                      <el-button @click="readInfo(scope.row.message_content)" style="color: #4E9F5B;" type="text" size="small">查看</el-button>
                    </template>
                  </el-table-column>
                  <el-table-column prop="message_create_time" align="center" label="发布时间">
                    <template slot-scope="scope">
                      <span>{{scope.row.message_create_time*1000 | getDate }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="message_state" align="center" label="状态">
                    <template slot-scope="scope">
                      <span>{{scope.row.message_state=='1'?'启用':'未启用' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column fixed="right" label="操作" align="center">
                    <template slot-scope="scope">
                      <el-button style="color: #4E9F5B !important;" @click="editNotice_(scope.row)" type="text" size="small">编辑</el-button>
                      <el-button @click="deleteNotice(scope.row.message_id)" style="color: #FF1A2E !important;" type="text" size="small">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
            </div>
        </div>
      </div>
  </div>
  <!--分页-->
  <div class="pageNum" id="pageNum">
    <Pagination style="margin-top:20px"
    v-show="rows>1"
    :total="rows"
    @change = "getNoticeList"
    :page.sync= "sendTab.page"
    :limit.sync="sendTab.limit"/>
  </div>

  <!-- 添加公告弹窗 -->
  <el-dialog id="addStation" title="公告发布" :visible.sync="addDialogVisible" @close="closeAddDialog" :close-on-press-escape="false" 
    :close-on-click-modal="false">
    <el-input rows='10' type="textarea" placeholder="请输入公告内容" v-model="addNotice.message_content">
    </el-input>
    <div class="flex align-center margin-top-16">
      <span class="margin-right-16">状态:</span>
      <el-switch v-model="addNotice.message_state" active-value="1"  inactive-value="0"></el-switch>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addNotice_">提 交</el-button>
    </span>
  </el-dialog>

  <!-- 查看信息 -->
  <el-dialog id="infoModelBox" title="查看公告内容" :visible.sync="showInfoModel" :close-on-press-escape='false' :close-on-click-modal='false'
    append-to-body :before-close="closeInfoModel">
      <div  class="solid padding-16 text-14 text-333 margin-top-24 radius-4 text-left" style="min-height: 225px;">
        <div v-html="content"></div>
      </div>
  </el-dialog>

  <!-- 编辑公告 -->
  <el-dialog id="addStation" title="编辑公告" :visible.sync="editDialogVisible" 
  @close="closeEditModel" :close-on-press-escape="false" 
  :close-on-click-modal="false">
      <el-input rows='10' type="textarea" placeholder="请输入公告内容" v-model="editNotice.message_content">
      </el-input>
      <div class="flex align-center margin-top-16">
        <span class="margin-right-16">状态:</span>
        <el-switch v-model="editNotice.message_state" active-value="1"  inactive-value="0"></el-switch>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sendEdit">修 改</el-button>
      </span>
  </el-dialog>
</div>
</template>
<script>
import {noticeList,noticeAdd,noticeDel,noticeEdit} from '@/api/http'
import Pagination from '@/components/pagination'
export default {
    data(){
      return{
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
        dataList: [], //表格数据
        sendTab:{
          page:1,
          limit:10,
          keyword:''
        },
        addNotice:{
          message_state:'1'
        },
        /* 添加公告弹窗*/
        addDialogVisible: false,
        textarea: '',
        state:false,//状态
        addNotice:{
          message_state:'1'
        },
        editNotice:{
          message_state:'',
          message_content:'',
          message_id:''
        },
        editDialogVisible:false,
        currentNoticID:'',
        /* 信息弹窗参数*/
        showInfoModel:false,
        title:'内容',
        content:'',
      }
    },
    created(){
        this.timeDefaultShow = new Date();
        this.timeDefaultShow.setMonth(new Date().getMonth() - 1);
        this.getNoticeList()
    },
    methods:{
      searKeyword(){
        console.log(this.sendData.keyword)
        if(!this.sendData.keyword) return this.getTabList();
        // return this.dataList.filter(v=>{
        //   console.log(v)
        //   return v.coupon_title.includes(this.sendData.keyword);
        // })
        var _this = this
        var newItems = []
        this.dataList.map(function(item){
          // console.log(item)
          if(!item.message_id.search(_this.sendData.keyword)){
            console.log(item)
            newItems.push(item)
          }
        })
        // return newItems
        console.log(newItems)
        this.dataList = newItems
      },
        //添加公告弹窗
        addNotice_(){
          noticeAdd(this.addNotice).then(res=>{
            console.log('添加公告',res)
            if(res.data.code == '1'){
              this.$message.success('发布成功')
              this.addNotice.message_content = ''
              this.addDialogVisible = false
              this.getNoticeList()
            }else{
              this.$message.error(res.info)
            }
          })
        },
        editNotice_(info){
          console.log(info)
          this.editDialogVisible = true
          this.editNotice.message_state = info.message_state
          this.editNotice.message_content = info.message_content
          this.editNotice.message_id = info.message_id
        },
        //修改公告内容按钮提交
        sendEdit(){
          noticeEdit(this.editNotice).then(res=>{
            console.log('修改公告内容：',res)
            if(res.code == '1'){
              this.$message.success('修改成功')
              this.editDialogVisible = false
              this.getNoticeList()
            }else{
              this.$message.error(res.info)
            }
          })
        },
        //删除公告内容
        deleteNotice(id){
          let that = this
          this.$confirm("此操作将删除当前公告内容, 是否继续?", "提示", {
				     confirmButtonText: "确定",
				     cancelButtonText: "取消",
				     type: "warning",
			    })
			    .then(() => {
            noticeDel({message_id:id}).then(res=>{
              console.log('是否同意删除当前公告内容',res.data)
              if(res.data.code == '1'){
                  that.$message.success('删除成功')
                  that.getNoticeList()
              }else{
                  that.$message.error(res.info)
              }
            })
			    })
			    .catch(() => {
			    	this.$message({
			    		type: "info",
			    		message: "已取消操作",
			    	});
			    });
        },
        //查看意见描述
        readInfo(text){
          this.content = text
          this.showInfoModel = true
        },
        //获取公共列表
        getNoticeList(){
            let that = this
            let tabDat = {}
            for (let item in this.sendTab) {
              if (that.sendTab[item] != '' && that.sendTab[item] != '999') {
                tabDat[item] = that.sendTab[item]
              }
            }
            noticeList(tabDat).then(res=>{
                console.log('公告数据：',res)
                let {list,page,count,limit}=res.data.data
                console.log(res.data.code,list,page,count,limit)
                if(res.data.code == '1' && list){
                  this.rows = count
                  this.dataList = list
                }else if(res.data.code=='1'&&res.data.data.csv_file){
                  window.open(res.data.data.csv_file)
                  this.sendTab.limit=this.rows
                }
            })
        },
        //查看内容
        readInfo(text){
          this.content = text
          this.showInfoModel = true
        },
          /* 改变获取列表参数 */
        /* 数据改变 */
        changeData(){
          this.sendTab.page=1
          this.getNoticeList()
        },
        /* 情况搜索框 */
        emptykeyword(e){
          if(e.length=='0'){
            this.changeData()
          }
        },
        //导出表格
        exportData(){
          this.sendTab.limit = 9999
          this.sendTab.page = 1
          this.getNoticeList()
        },
        /* 选择时间*/
        //打开弹窗
        showModel(title){
            this.modelTitle = title
            this.showTabModel = true
            this.getModelTab.page = 1
            this.getFinanceRefund()
        },
        //获取退款列表
        /* 退款理由 */
        showReason(reason) {
          this.content = reason
          this.showInfoModel = true
        },
        /* 附件*/
        showpicture(arr) {
          this.showImgModel = true
          this.imgContent = arr
        },
        closeImgModel() {
          this.showImgModel = false
        },
        closeInfoModel() {
          this.showInfoModel = false
        },
        //退款成功，刷新列表
        changeList(){
            this.getFinanceRefund()
        },
        //关闭编辑公告弹窗
        closeEditModel(){
          this.editDialogVisible = false
        },
        closeAddDialog(){
          this.addDialogVisible = false
        },
        //导出
        exportTab(){
          this.sendTab.limit = 9999
          this.sendTab.page = 1
          this.getNoticeList()
        }
    },
    components:{
        Pagination
    }
}
</script>
<style>
#noticeManage .header_search .el-input__inner {
  height: 20px !important;
  border: none !important;
  padding: 0 !important;
}
</style>